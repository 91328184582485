
import '@/assets/createProductWizzard.css';
import { Product } from '@/models/Product';
import StepsNavigation from '@/components/CreateProductWizzard/StepsNavigation.vue';
import { generalStore } from '@/store';
import { computed, ref, reactive } from 'vue';
import router from '@/router';
import useProduct from '@/modules/useProduct';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { useI18n } from 'vue-i18n';

export default {
    props: {
        subStep: {
            type: String,
            required: true
        },
        productId: {
            type: String
        }
    },
    components: {
        StepsNavigation,
        Cropper
    },
    async setup(props: any) {
        const { t } = useI18n();
        const { saveProduct, getProduct, getWizzardStep } = useProduct();
        const showPopup = ref(false);
        const calendar = ref();
        const showHelp = ref(false);

        if (props.productId) await getProduct(props.productId);

        const product = computed<Product>(() => generalStore.getters.getNewProduct);

        if (!product.value.calendarColor) {
            product.value.calendarColor = '#2e09d6';
        }
        const fileUpload = ref(null);

        const changeStep = (stepName: string, subStep: string) => {
            router.push({ name: stepName, params: { subStep: subStep, productId: product.value.id } });
        };

        const submitForm = async (
            event: any,
            nextStep: {
                stepName: string;
                subStep: string;
            }
        ) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                let newColor = product.value.calendarColor;
                if (!product.value.calendarColor.includes('#')) {
                    product.value.calendarColor = '#' + newColor;
                }
                product.value.wizzardStep = getWizzardStep('createProductStepOne', props.subStep);
                const savedProduct = await saveProduct(product.value);
                if (savedProduct) {
                    newColor = '';
                    router.push({ name: nextStep.stepName, params: { subStep: nextStep.subStep, productId: savedProduct.id } });
                }
            }
        };
        function showHelpFunc() {
            showHelp.value = !showHelp.value;
            showPopup.value = false;
        }

        const imgSrc = ref('');
        const cropperLib = ref();
        const newSavedImage = ref('');
        if (product.value.productImages && product.value.productImages.length) {
            newSavedImage.value = product.value.productImages[0].pathMedium;
        }

        const uploadImage = async (e: any) => {
            newSavedImage.value = '';
            swal.showLoading();
            let file = e.target.files[0];

            const fileTypes: string[] = ['image/jpeg', 'image/jpg', 'image/png'];
            if (file) {
                if (file.size > 5242880) {
                    swal.fire({
                        icon: 'error',
                        text: 'File size should be less or equal to 5 MB'
                    });
                    if (!fileTypes.find(t => t === file.type)) {
                        swal.fire({
                            icon: 'error',
                            text: 'Allowing file types: jpeg, jpg, png'
                        });
                    }
                    file = null;
                    return;
                }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader();
                    reader.onload = (event: any) => {
                        imgSrc.value = event.target.result;
                    };
                    reader.readAsDataURL(file);
                }
            }
            swal.close();
        };

        function clearImage() {
            newSavedImage.value = '';
            imgSrc.value = '';
        }
        const saveImage = async () => {
            swal.showLoading();
            const { canvas } = cropperLib.value.getResult();
            if (canvas) {
                const fd = new FormData();
                canvas.toBlob(
                    async (blob: any) => {
                        fd.append('file', blob);
                        product.value.productImages?.forEach(async image => {
                            const deleteResponse = await api.deleteProductImage(image.id, true);
                            if (deleteResponse && deleteResponse.data) {
                                product.value.productImages = product.value.productImages?.filter(d => d.id !== image.id);
                            }
                        });
                        const uploadResponse = await api.uploadFile(fd, product.value.id, true);
                        if (uploadResponse.data && uploadResponse.data.errorCode == 802) {
                            await swal.fire({
                                icon: 'error',
                                title: t('error-pop-up.oops'),
                                text: t('wizard.step-three.upload-image-error.small-resolution')
                            });
                            return;
                        }
                        if (uploadResponse.errorMessage) {
                            await swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: uploadResponse.errorMessage });
                            return;
                        }

                        if (uploadResponse.data?.productImage) {
                            const productImage = uploadResponse.data.productImage;

                            imgSrc.value = '';
                            newSavedImage.value = productImage.pathMedium;
                            swal.close();

                            productImage.isMainImage = true;
                            product.value.productImages = [productImage];
                        }
                    },
                    'image/jpeg',
                    0.9
                );
            }
        };

        return {
            product,
            changeStep,
            submitForm,
            uploadImage,
            clearImage,
            imgSrc,
            newSavedImage,
            saveImage,
            cropperLib,
            calendar,
            showPopup,
            showHelpFunc,
            showHelp,
            fileUpload
        };
    }
};
